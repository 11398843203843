/*---------------------------
 CSS Index  
****************************

1. variables
2. typography
3. spacing
4. reset
5. forms
6. mixins
7. shortcode
8. common
9. animations
10. text-animation
11. sal animation
12. header-one
13. mobile menu
14. breadcrumb
14. Button
15. banner
16. working-process
17. counterup
18. about
19. contact
20. service
21. back-totop
22. team-area
22. produsct
23. portfolio
24. testimonial
25. blog
26. brand-area
27. cta
28. footer-one
29. vedio-button
30. faq
31. accordion
32. pricing
33. appoinment
33. preloader
35. Dark Version style



/* Default  */
@import'default/variables';
@import'default/typography';
@import'default/spacing';
@import'default/reset';
@import'default/forms';
@import'default/mixins';
@import'default/shortcode';
@import'default/common';
@import'default/animations';
@import'default/text-animation';
@import'default/sal';


/* Header area style  */

@import'header/header-one';
@import'header/mobile-menu';
@import'header/breadcrumb';


/* elements area style  */

@import'elements/button';
@import'elements/banner';
@import'elements/working-process';
@import'elements/counterup';
@import'elements/about';
@import'elements/contact';
@import'elements/service';
@import'elements/back-totop';
@import'elements/team-area';
@import'elements/produsct';
@import'elements/portfolio';
@import'elements/testimonial';
@import'elements/blog';
@import'elements/brand-area';
@import'elements/cta';
@import'elements/footer-one';
@import'elements/vedio-button';
@import'elements/faq';
@import'elements/accordion';
@import'elements/pricing';
@import'elements/appoinment';
@import'elements/preloader';
@import'elements/cursore';
@import'elements/image-reveal';
@import'elements/dark-version';
