
// team area style

.container{
    &.container-2{
        max-width: 1679px;
    }
}


.single-team-one{
    position: relative;
    .inner-content{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        transition: all .5s;
        .main-wrapper{
            text-align: center;
            position: relative;
            z-index: 1;
            transform: scale(0);
            transition: .5s;
            &::after{
                position: absolute;
                content: '';
                width: 353px;
                height: 354px;
                background: #fff;
                opacity: .9;
                border-radius: 50%;
                display: flex;
                top: 50%;
                left: 50%;
                transform: translate(-50% ,-50%);
                z-index: -1;
            }
            a{
                .title{
                    color: #111A2E;
                    font-weight: 700;
                    font-size: 24px;
                    line-height: 29px;
                    margin-bottom: 1px;
                    transition: .3s;
                }
                &:hover{
                    .title{
                        color: var(--color-primary);
                    }
                }
            }
            .social-wrapper{
                list-style: none;
                padding-left: 0;
                display: flex;
                justify-content: center;
                margin: 0;
                li{
                    margin-left: 15px;
                    margin-right: 15px;
                    margin-top: 15px;
                    margin-bottom: 0;
                    a{
                        position: relative;
                        z-index: 1;
                        i{
                            font-size: 12px;
                            color: #111A2E;
                            transition: .3s;
                        }
                        &::after{
                            position: absolute;
                            content: '';
                            width: 30px;
                            height: 30px;
                            background: #E6E6E6;
                            left: 50%;
                            top: 60%;
                            transform: translate(-50%, -50%);
                            z-index: -1;
                            border-radius: 50%;
                            transition: .3s;
                        }
                        &:hover{
                            i{
                                color: #fff;
                            }
                            &::after{
                                background: var(--color-primary);
                            }
                        }
                    }
                }
            }
        }
    }
    &:hover{
        .inner-content{
            .main-wrapper{
                transform: scale(1);
            }
        }
    }
}

.single-contact-one-inner{
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 15px;
    height: 100%;
    .thumbnail{
        display: block;
        overflow: hidden;
        border-radius: 15px 15px 0 0;
        img{
            width: 100%;
            transition: .3s;
        }
        &:hover{
            img{
                transform: scale(1.1);
            }
        }
    }
    .content{
        display: flex;
        align-items: center;
        padding: 40px;
        @media #{$sm-layout} {
            padding: 15px;
        }
        .icone{
            margin-right: 20px;
        }
        .info{
            span{
                margin-bottom: 0;
                color: var(--color-primary);
                font-weight: 500;
            }
            a{
                h5{
                    margin-bottom: 0;
                    transition: .3s;
                    @media #{$lg-layout} {
                        font-size: 18px;
                    }
                    @media #{$sm-layout} {
                        font-size: 18px;
                    }
                }
                &:hover{
                    h5{
                        color: var(--color-primary);
                    }
                }
            }
        }
    }
}

.team-single-one-start{
    background: #fff;
    border-radius: 8px;
    transition: .3s;
    box-shadow: 0px 9px 18px rgba(24, 16, 16, .05);
    &:hover{
        transform: translateY(-20px);
    }
    .team-image-area{
        a{
            overflow: hidden;
            display: block;
            position: relative;
            border-radius: 8px 8px 0 0;
            &:hover{
                img{
                    transform: scale(1.1);
                }
            }
            img{
                width: 100%;
                transition: .3s;
            }
            // socail area start
            .team-social{
                position: absolute;
                bottom: 30px;
                right: 30px;
                .main{
                    i{
                        padding: 16px;
                        background: #fff;
                        border-radius: 50%;
                        font-size: 16px;
                        line-height: 12px;
                        font-weight: 500;
                        color: var(--color-primary);
                        transition: 0.3s;
                    }
                }
                &:hover{
                    .main{
                        i{
                            background: var(--color-primary);
                            color: #fff;
                        }
                    }
                }
                .team-social-one{
                    display: flex;
                    flex-direction: column;
                    position: absolute;
                    transform: translateY(50px);
                    transition: 1s;
                    opacity: 0;
                    i{
                        background: #fff;
                        border-radius: 60%;
                        font-size: 16px;
                        line-height: 23px;
                        font-weight: 500;
                        color: var(--color-primary);
                        transition: 0.3s;
                        margin-bottom: 10px;
                        height: 45px;
                        width: 45px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        &:hover{
                            background: var(--color-primary);
                            color: #fff;
                        }
                    }
                }
                &:hover{
                    .main{
                        opacity: 0;
                    }
                    .team-social-one{
                        transform: translateY(-96%);
                        z-index: 2;
                        opacity: 1;
                    }
                }
            }
        }
    }
    .single-details{
        padding: 28px 30px 27px 30px;
        a{
            .title{
                margin-bottom: 0px;
                transition: .3s;
            }
            &:hover{
                .title{
                    color: var(--color-primary);
                }
            }
        }
        p{
            color: var(--color-primary);
            font-size: 16px;
        }
    }
}

// team details style hear
.team-details-support-wrapper{
    display: flex;
    align-items: center;
    margin-bottom: 25px;
    i{
        height: 60px;
        width: 60px;
        border-radius: 15px;
        border: 1px solid #EDEDED;
        box-shadow: 0px 9px 18px rgba(24, 16, 16, 0.05);
        display: flex;align-items: center;
        justify-content: center;
        font-weight: 400;
        font-size: 20px;
        line-height: 20px;
        color: var(--color-primary);
    }
    .support-innner{
        margin-left: 20px;
        span{
            margin-bottom: 5px;
            color: #5D666F;
            font-size: 16px;
            font-weight: 400;
        }
        a{
            .title{
                margin-bottom: 0;
                transition: .3s;
                @media #{$small-mobile} {
                    font-size: 14px;
                }
            }
            &:hover{
                .title{
                    color: var(--color-primary);
                }
            }
        }
    }
}
p.disc {
    font-size: 16px;
    line-height: 26px;
}
.details-right-inner{
    .title-area{
        margin-bottom: 16px;
        span{
            color: #5D666F;
            font-weight: 500;
            font-size: 16px;
        }
        .title{
            margin-top: 0px;
            margin-bottom: 12px;
            font-size: 40px;
        }
    }
    p{
        &.disc{
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 33px;
            color: #5D666F;
        }
    }

    .rts-btn{
        margin-top: 30px;
        display: block;
        max-width: max-content;
    }
}


.single-about-skill-inner{
    .title{
        position: relative;
        margin-bottom: 40px;
        &::after{
            content: '';
            position: absolute;
            bottom: -20px;
            left: 0;
            width: 100%;
            height: 1px;
            background: #F1F1F1;
        }
    }
}


.rts-progress-one-wrapper{
    .meter {
    // background: #ccc;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    box-shadow: inset 0 -1px 1px rgba(255,255,255,0.3);
    display: block;
    height: 8px;
    margin-bottom: 10px;
    // padding: 8px;
    position: relative;
  
  > span {
    display: block;
    height: 100%;
    background-color: var(--color-primary);
    position: relative;
    overflow: hidden;
    transition: width 2s ease-out;
  }
}

.orange {
  > span {
    background-color: var(--color-primary);
  }
}

.red {
  > span {
    background-color: var(--color-primary);
  }
}

.cadetblue {
  >span {
    background-color: var(--color-primary);
  }
}
}

.rts-progress-one-wrapper{
    .single-progress{
        margin-bottom: 20px;
        border-bottom: 1px solid #F1F1F1;
    }
    .progress-top{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 15px;
        p{
            margin-bottom: 0;
            color: var(--color-primary);
            font-weight: 500;
            font-size: 16px;
        }
        .persectage{
            color: var(--color-primary);
            font-weight: 500;
            font-size: 16px;
        }
    }
}

.education-skill-wrapper{
    display: flex;
    box-shadow: 0px 13px 21px rgba(0, 0, 0, 0.03);
    flex-wrap: wrap;
    .number-area{
        position: absolute;
        height: 60px;
        width: 60px;
        background: var(--color-primary);
        border-radius: 50%;
        left: -4%;
        top: -10%;
        display: flex;
        align-items: center;
        justify-content: center;
        p{
            font-size: 22px;
            color: #fff;
            margin-left: auto;
            margin-top: auto;
            margin-right: 12px;
            margin-bottom: 8px;        
            span{
                font-size: 12px;
                color: #fff;
                margin-left: -3px;
            }
        }
    }
    .single-skill{
        padding: 30px 60px;
        border: 1px solid #F1F1F1;
        width: 50%;
        position: relative;
        overflow: hidden;
        transition: .3s;
        background: #fff;
        @media #{$laptop-device} {
            padding: 30px 11px 30px 50px;
        }
        @media #{$smlg-device} {
            padding: 30px 10px 30px 40px;
            width: 100%;
        }
        @media #{$sm-layout} {
            padding: 30px 10px 30px 50px;
        }
        .experience{
            margin-bottom: 3px;
        }
        .date{
            span{
                color: var(--color-primary);
            }
        }
        &:hover{
            transform: scale(1.05);
        }
    }
}


.bg-team{
    background: #F7F7F7;
}

.single-team-two{
    padding: 40px;
    background: #fff;
    border-radius: 15px;
    display: flex;
    align-items: center;
    box-shadow: 0px 18px 33px rgba(15, 15, 45, 0.05);
    height: 100%;
    @media #{$md-layout} {
        align-items: flex-start;
        padding: 20px;
    }
    @media #{$sm-layout} {
        flex-direction: column;
        justify-content: flex-start;
        padding: 20px;
        align-items: flex-start;
    }
    .thumbnail{
        overflow: hidden;
        display: block;
        border-radius: 15px;
        @media #{$sm-layout} {
            width: 100%;
        }
        img{
            transition: .3s;
            border-radius: 15px;
            @media #{$sm-layout} {
                width: 100%;
            }
        }
        &:hover{
            img{
                transform: scale(1.2);
            }
        }
    }
    .inner{
        margin-left: 30px;
        @media #{$md-layout} {
            margin-left: 15px;
        }
        @media #{$sm-layout} {
            margin-left: 0;
            margin-top: 15px;
        }
        span{
            color: #6F737B;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
        }
        a{
            h5{
                margin-bottom: 0;
                color: #111A2E;
                font-weight: 700;
                font-size: 24px;
                line-height: 29px;
                @media #{$md-layout} {
                    font-size: 20px;
                }
            }
        }
    }
}

.social-wrapper-two{
    ul{
        list-style: none;
        display: flex;
        padding-left: 0;
        margin-top: 20px;
        margin-bottom: 0;
        li{
            margin: 0;
            padding: 0;
            a{
                transition: .3s;
                i{
                    width: 35px;
                    height: 35px;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    color: var(--color-primary);
                    margin-right: 7px;
                    background: #FFFFFF;
                    transition: .3s;
                    box-shadow: 0px 10px 12px rgba(179, 179, 179, 0.21), inset 1px 4px 5px rgba(174, 172, 243, 0.3);
                }
                &:hover{
                    i{
                        color: #fff;
                        transform: translateY(-5px);
                        box-shadow: 0px 10px 12px rgb(28 82 205 / 37%), inset 1px 4px 5px rgba(0, 0, 0, 0.3);
                        background: var(--color-primary);
                    }
                }
            }
        }
    }
}



.team-single-digital{
    overflow: hidden;
    position: relative;
    a{
        &.thumbnail{
            position: relative;
            height: 100%;
            display: block;
            z-index: 1;
            &::after{
                position: absolute;
                content: '';
                right: 0;
                bottom: 0;
                width: 100%;
                height: 100%;
                opacity: 0;
                z-index: 0;
                transition: .4s;
                background: linear-gradient(180deg, #0000005d 0%, #000 100%);  
            }
            img{
                width: 100%;
            }
            .inner{
                position: absolute;
                transition: .3s;
                transition: .3s;
                left: 30px;
                top: 100%;
                z-index: 5;
                .title{
                    color: #fff;
                    margin-bottom: 0px;
                }
                span{
                    color: rgba(255, 255, 255, 0.308);
                }
            }
            &:hover{
                &::after{
                    right: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                }
                .inner{
                    left: 30px;
                    top: 72%;
                }
            }
        }
    }
}
.mt-dec-40{
    margin-top: -47px;
}

.title-area{
    &.team-h5{
        span{
            font-weight: 300;
            font-size: 30px;
            line-height: 70px;
            color: #6F737B;
            font-family: var(--font-secondary);
        }
        .title{
            font-weight: 400;
            font-size: 60px;
            line-height: 70px;
            color: #111A2E;
            margin-top: 0;
            @media #{$large-mobile} {
                font-size: 43px;
                line-height: 41px;
            }
        }
        a{
            &.call-btn{
                color: #fff;
                background: #EFEFFE !important;
                svg{
                    path{
                        fill: #fff;
                        transition: .3s
                    }
                }
                &::before{
                    background: var(--color-primary);
                }
                &:hover{
                    color: var(--color-primary);
                    svg{
                        path{
                            fill: var(--color-primary);
                        }
                    }
                }
            }
        }
    }
}
.title-area{
    &.ho-5{
        span{
            font-weight: 300;
            font-size: 30px;
            line-height: 70px;
            color: #6F737B;
            font-family: var(--font-secondary);
        }
        .title{
            font-weight: 400;
            font-size: 60px;
            line-height: 70px;
            color: #111A2E;
            margin-top: 0;
            @media #{$large-mobile} {
                font-size: 43px;
                line-height: 41px;
            }
        }
    }
}

.rts-team-area{
    &.style-3{
        background: #f6f6f6;
        padding-top: 70px;
        .team-inner-two{
            background: #fff;
            margin-bottom: 15px;
            &:hover{
                .thumbnail{
                    .social{
                        right: 0;
                    }
                }
            }
            .thumbnail{
                border-radius: 0;
                position: relative;
                overflow: hidden;
                img{
                    width: 100%;
                }
                .social{
                    position: absolute;
                    right: -50px;
                    bottom: 0;
                    display: inline-grid;
                    background: var(--color-primary);
                    padding: 12px 0;
                    width: 50px;
                    text-align: center;
                    color: #fff;
                    transition: all .4s;
                    a{
                        margin: 5px 0;
                        transition: all .4s;
                        &:hover{
                            color: #0B101C;
                        }
                    }
                }
            }
            .inner-content{
                padding: 30px;
                text-align: center;
                .header{
                    .title{
                        margin-bottom: 5px;
                    }
                    span{
                        color: var(--color-primary);
                    }
                }
            }
        }
    }
    &.style-4{
        .team-inner-two{
            position: relative;
            width: 100%;
            transition: all .6s;
            &.inner{
                margin-bottom: 100px;
            }
            &:hover{
                .acquaintance-area{
                    .team-desc{
                        max-height: 400px;
                        transform: scaleY(1);
                        transition: all .4s,max-height 1.5s;
                        padding: 15px 0;
                    }
                }
            }
            a{
                &.thumbnail{
                    overflow: hidden;
                    display: block;
                    border-radius: 15px;
                    img{
                        width: 100%;
                        transition: .3s;
                    }
                    &:hover{
                        img{
                            transform: scale(1.15);
                        }
                    }
                }
            }
            .acquaintance-area{
                position: absolute;
                right: 50%;
                left: unset;
                width: 85%;
                padding: 20px;
                transform: translateX(50%);
                bottom: -20%;
                background: #fff;
                border-radius: 15px;
                transition: all .6s;
                box-shadow: 0px 4px 27px #00000012;
                text-align: center;
                @media #{$md-layout} {
                    bottom: 0;
                }
                @media #{$sm-layout} {
                    bottom: 0;
                }
                .header{
                    padding: 0 0 5px 0 ;
                    border: 0;
                    @media #{$smlg-device} {
                        padding: 20px 62px;
                    }
                    .title{
                        font-size: 22px;
                        margin-bottom: 5px;
                        @media #{$smlg-device} {
                            font-size: 20px;
                        }
                    }
                    span{
                        color: var(--color-primary);
                        font-weight: 400;
                        font-size: 16px;
                    }
                }
                .team-desc{
                    font-size: 14px;
                    margin-bottom: 0;
                    color: #777;
                    position: relative;
                    padding: 5px 0;
                    z-index: 1;
                    max-height: 0;
                    opacity: 1;
                    line-height: 24px;
                    overflow: hidden;
                    transform: scaleY(0);
                    transform-origin: bottom;
                    transition: all .8s,max-height .8s;
                    &::before{
                    content: "";
                    position: absolute;
                    background: var(--color-primary);
                    width: 50px;
                    height: 2px;
                    right: 50%;
                    top: 3px;
                    transform: translateX(50%);
                    }
                }
                .acquaintance-social{
                    padding: 5px 84px 5px 84px;
                    margin-right: -30px;
                    @media #{$smlg-device} {
                        padding: 20px 62px;
                        margin-right: -30px;
                    }
                    a{
                        position: relative;
                        z-index: 1;
                        transition: .3s;
                        margin-right: 30px;
                        i{
                            font-size: 14px;
                            color: var(--color-primary);
                            transition: .3s;
                        }
                        &::after{
                            position: absolute;
                            content: '';
                            background: #F6F6F6;
                            height: 40px;
                            width: 40px;
                            border-radius: 50%;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            z-index: -1;
                            transition: .3s;
                        }
                        &:hover{
                            i{
                                color: #fff;
                            }
                            &::after{
                                background: var(--color-primary);
                            }
                        }
                    }
                }
            }
        }
    }
    &.style-5{
        .team-inner-two{
            margin-bottom: 30px;
            .thumbnail{
                border-radius: 50%;
            }
            .team-content{
                text-align: center;
                margin-top: 30px;
                .header{
                    .title{
                        margin-bottom: 5px;
                    }
                    span{
                        color: var(--color-primary);
                    }
                }
                .acquaintance-social{
                    margin-top: 10px;
                    a{
                        margin-right: 5px;
                        background: #fff;
                        color: #4a4a4a;
                        width: 35px;
                        height: 35px;
                        display: inline-block;
                        line-height: 35px;
                        transition: all .3s;
                        &:hover{
                            background: var(--color-primary);
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}