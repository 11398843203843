
// pricing area style

.single-pricing-area-h2{
    background: #FFFFFF;
    box-shadow: 0px 8px 37px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
    .pricing-head{
        padding: 18px 50px;
        background: rgba(59, 56, 235, 0.1);
        border-radius: 20px 20px 0px 0px;
        @media #{$large-mobile} {
            padding: 18px 15px;
        }
        .title{
            margin-bottom: 0;
            color: #3B38EB;
            font-weight: 700;
            font-size: 24px;
            line-height: 29px;
        }
    }
    .pricing-body{
        padding: 35px 50px;
        @media #{$large-mobile} {
            padding: 18px 15px;
        }
        .title{
            font-weight: 700;
            font-size: 40px;
            line-height: 48px;
            span{
                font-weight: 700;
                font-size: 16px;
                line-height: 19px;
                margin-left: -10px;
            }
        }
        p{
            &.disc{
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                margin-bottom: 0;
                padding-bottom: 30px;
                border-bottom: 1px solid #ECECEC;
                margin-bottom: 30px;
            }
        }
        .feature-area{
            .single-feature{
                display: flex;
                align-items: center ;
                span{
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 34px;
                    color: #111A2E;
                    margin-left: 10px;
                }
                i{
                    color: #3B38EB;
                    font-weight: 900;
                    font-size: 16px;
                    line-height: 34px;
                }
            }
        }
        a{
            &.rts-btn{
                margin-top: 30px;
                border: 1px solid #3B38EB;
                display: block;
                text-align: center;
            }
        }
    }
    &.standared{
        .pricing-head{
            background: #3B38EB;
            .title{
                color:#fff;
            }
        }
        a{
            &.rts-btn{
                background: #fff;
                color: #fff;
                &::before{
                    background: var(--color-primary);
                }
                &:hover{
                    color: var(--color-primary);
                }
            }
        }
    }
}