.bg-light-testimonial{
    background: #F7F7F7;
    padding: 120px 0;
    max-width: 1680px;
    margin: auto;
    @media #{$sm-layout} {
        padding: 80px 0;
    }
    @media #{$large-mobile} {
        padding: 60px 0;
    }
}

.slide-div{
    width: 130%;
    @media #{$sm-layout} {
        width: 100%;
    }
}

.single-testimonial-one{
    background: #fff;
    padding: 40px;
    border-radius: 20px;
    @media #{$large-mobile} {
        padding: 20px;
    }
    .header-area{
        display: flex;
        align-items: center;
        margin-bottom: 30px;
        @media #{$large-mobile} {
            margin-bottom: 15px;
        }
        img{
            margin-right: 23px;
        }
        .name-desig{
            a{
                h6{
                    margin-bottom: 1px;
                    color: #111A2E;
                    transition: .3s;
                }
                &:hover{
                    h6{
                        color: var(--color-primary);
                    }
                }
            }
            p{
                font-weight: 400;
                font-family: var(--font-secondary);
                font-size: 16px;
                span{
                    font-weight: 600;
                    font-size: 16px;
                    color: var(--color-primary);
                }
            }
        }
    }
    .body{
        padding-bottom: 30px;
        border-bottom: 2px solid #EFEFEF;
        p{
            &.disc{
                font-weight: 400;
                font-size: 20px;
                line-height: 30px;
            }
        }
        @media #{$large-mobile} {
            margin-bottom: 0px;
            padding-bottom: 15px;
        }
    }
    .footer{
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-top: 17px;
        .stars-area{
            ul{
                list-style: none;
                padding-left: 0;
                margin: 0;
                display: flex;
                li{
                    margin: 0;
                    margin-right: 5px;
                    a{
                        i{
                            color: var(--color-primary);
                        }
                    }
                }
            }
        }
    }
}


.swiper-button-next,
.swiper-button-prev{
    @media #{$large-mobile} {
        display: none;
    }
    width: 55px;
    display: flex;
    &:hover{
        &::after{
            background: var(--color-primary);
            color: #fff;
        }
    }
}
.swiper-button-next{
    &::after{
        content: "\f324";
        font-family: "Font Awesome 5 Pro";
        font-size: 16px;
        color: #1C2539;
        padding: 10px;
        background: #fff;
        box-shadow: 0px 10px 20px rgb(24 16 16 / 6%);
        border-radius: 8px;
        transition: 0.3s;
        width: 55px;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.swiper-button-prev{
    &::after{
        content: "\f323";
        font-family: "Font Awesome 5 Pro";
        font-size: 16px;
        color: #1C2539;
        padding: 10px;
        background: #fff;
        box-shadow: 0px 10px 20px rgb(24 16 16 / 6%);
        border-radius: 8px;
        transition: 0.3s;
        width: 55px;
        height: 55px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.title-area-testi{
    position: relative;
    .swiper-button-prev{
        right: 80px;
        left: auto;
    }
}


.bg_testimonials-h2{
    background-image: url(../images/testimonials/bg-01.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

.testimonials-area-h2{
    span{
        color: #fff;
    }
    .title{
        color: #fff;
    }
}


.single-testimonials-h2{
    background: #fff;
    padding: 50px;
    border-radius: 15px;
    @media #{$large-mobile} {
        padding: 15px;
    }
    .body{
        padding-bottom: 20px;
        border-bottom: 1px solid #ECECF2;
        .title{
            color: var(--color-primary);
        }
    }
    .footer{
        margin-top: 28px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left{
            display: flex;
            align-items: center;
            .thumbnail{
                overflow: hidden;
                display: block;
                border-radius: 15px;
                img{
                    width: 46px;
                    transition: .3s;
                    height: 46px;
                }
                &:hover{
                    img{
                        transform: scale(1.2);
                    }
                }
            }

            .desig{
                margin-left: 15px;
                a{
                    .title{
                        margin-bottom: 2px;
                        font-size: 20px;
                        transition: .3s;
                        @media #{$small-mobile} {
                            font-size: 13px;
                        }
                    }
                    &:hover{
                        .title{
                            color: var(--color-primary);
                        }
                    }
                }
                p{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    span{
                        color: #3B38EB;
                        font-weight: 700;
                        font-size: 14px;
                        line-height: 18px;
                    }
                }
            }
        }
        .right{
            .stars-area{
                ul{
                    list-style: none;
                    display: flex;
                    margin: 0;
                    @media #{$small-mobile} {
                        padding-left: 0;
                    }
                    li{
                        margin: 0;
                        a{
                            i{
                                font-weight: 900;
                                font-size: 16px;
                                line-height: 16px;
                                color: #3B38EB;
                                margin-left: 5px;
                            }
                        }
                    }
                }
            }
        }
    }
}

.single-testimonials-h2 .body{
    p{
        &.disc{
            font-weight: 400;
            font-size: 18px;
            line-height: 32px;
        }
    }
}


.testimonial-5-bg{
    background-image: url(../images/testimonials/bg-02.jpg);
}

.testimonials-h5-wrapper{
    .inner-wrapper-h5{
        
        p{
            &.disc{
                font-weight: 400;
                font-size: 36px;
                line-height: 50px;
                color: #111A2E;
                text-transform: uppercase;
                margin-top: 35px;
                margin-bottom: 30px;
                @media #{$large-mobile} {
                    font-size: 14px;
                    line-height: 24px;
                }
            }
        }
        span{
            &.name{
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: var(--color-primary);
                font-family: var(--font-secondary);
                text-transform: uppercase;
            }
        }
    }
    .swiper-button-wrapper{
        .swiper-button-next{
            &::after{
                background: transparent;
                box-shadow: none;
                background-image: url(../images/testimonials/logo/right.svg);
                background-repeat: no-repeat;
                background-position: center;
                content: '';
            }
        }
        .swiper-button-prev{
            &::after{
                background: transparent;
                box-shadow: none;
                background-image: url(../images/testimonials/logo/left.svg);
                background-repeat: no-repeat;
                background-position: center;
                content: '';
            }
        }
        .swiper-button-next, .swiper-button-prev{
            top: 57%;
        }
    }
}