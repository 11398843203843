// blog style hear

.rts-blog-post-area-one{
    padding: 30px;
    background: #fff;
    border-radius: 20px;
    box-shadow: 0px 8px 37px rgba(0, 0, 0, 0.07);
    @media #{$large-mobile} {
        padding: 15px;
    }
    a{
        margin-bottom: 21px;
        &.thumbnail{
            overflow: hidden;
            display: block;
            border-radius: 20px;
            img{
                width: 100%;
                transition: .3s;
                height: auto;
            }
            &:hover{
                img{
                    transform: scale(1.2);
                }
            }
        }
    }
    a{
        .title{
            font-weight: 700;
            font-size: 24px;
            line-height: 30px;
            padding-bottom: 18px;
            border-bottom: 1px solid  #E8E8E8;
            transition: .3s;
            @media #{$small-mobile} {
                font-size: 18px;
                 line-height: 26px;
            }
        }
        &:hover{
            .title{
                color: var(--color-primary);
            }
        }
    }
    .blog-footer{
        display: flex;
        align-items: center;
        .time{
            display: flex;
            align-items: center;
            i{
                color: var(--color-primary);
            }
            span{
                color: #6F737B;
                font-weight: 500;
                font-size: 16px;
                line-height: 21px;
                margin-left: 8px;
                @media #{$small-mobile} {
                    font-size: 13px;
                    margin-bottom: -4px;
                }
            }
            &.user{
                margin-left: 20px;
            }
        }
    }
}
.shape-blog-one{
    overflow: hidden;
    position: relative;
    z-index: 1;
    &::after{
        position: absolute;
        z-index: -1;
        content: '';
        top: -15%;
        left: 55%;
        height: 500px;
        width: 500px;
        animation: jump-3 5s linear infinite;
        background-size: contain;
        background-image: url(../images/blog/shape/01.png);
    }
}


.blog-single-two-wrapper{
    background: #FFFFFF;
    box-shadow: 0px 8px 37px rgba(0, 0, 0, 0.07);
    border-radius: 20px;
    height: 100%;
    .thumbnail{
        position: relative;
        overflow: hidden;
        display: block;
        border-radius: 20px 20px 0px 0px;
        z-index: 1;
        img{
            border-radius: 20px 20px 0px 0px;
            transition: 1s;
            width: 100%;
        }
        &:hover{
            img{
                transform: scale(1.2);
            }
        }
        span{
            &.badge-one{
                position: absolute;
                top: 30px;
                left: 30px;
                font-weight: 700;
                font-size: 14px;
                line-height: 17px;
                color: #fff;
                text-transform: uppercase;
                display: block;
                padding: 9px 15px;
                background: #3B38EB;
                border-radius: 8px;
                z-index: 0;
            }
        }
    }
    .inner{
        padding: 25px 40px 40px 40px;
        @media #{$large-mobile} {
            padding: 15px;
        }
        .admin-area{
            padding-bottom: 25px;
            display: flex;
            align-items: center;
            border-bottom: 1px solid #EEEEEE;
            img{
                width: 50px;
                height: 50px;
            }
            .details{
                margin-left: 10px;
                span{
                    margin-bottom: 0;
                }
                p{
                    margin-bottom: 0;
                    margin-top: -5px;
                    color: #111A2E;
                    font-weight: 700;
                    font-size: 16px;
                    font-family: var(--font-secondary);
                }
            }
        }
        .body{
            padding-top: 25px;
            .date{
                margin-bottom: 15px;
                i{
                    color: var(--color-primary);
                    font-weight: 900;
                    font-size: 16px;
                    line-height: 16px;
                }
                span{
                    color: #6F737B;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    margin-left: 10px;
                    font-family: var(--font-secondary);
                }
            }
            a{
                .title{
                    margin-bottom: 20px;
                    transition: .3s;
                }
                &:hover{
                    .title{
                        color: var(--color-primary);
                    }
                }
            }
        }
    }
}

.blog-single-post-listing{
    margin-right: 30px;
    border: 1px solid #E6E9F0;
    margin-bottom: 50px;
    @media #{$smlg-device} {
        margin-right: 0;
    }
    // details style hear...
    &.details{
        border-radius: 15px;
        .thumbnail{
            border-radius: 15px 15px 0 0;
            &.details{
                border-radius: 15px;
                width: 100%;
                max-width: max-content;
                @media #{$smlg-device} {
                    max-width: 100%;
                }
                @media #{$md-layout} {
                    margin-bottom: 20px;
                }
                @media #{$sm-layout} {
                    margin-bottom: 20px;
                }
                @media #{$large-mobile} {
                    margin-bottom: 0;
                }
            }
        }
        .rts-quote-area{
            padding: 50px;
            background: #F6F6F6;
            border-radius: 15px;
            margin-bottom: 40px;
            @media #{$md-layout} {
                padding: 30px;
            }
            @media #{$sm-layout} {
                padding: 10px;
                margin-bottom: 25px;
            }
            @media #{$small-mobile} {
                margin-top: 15px;
            }
            .title{
                margin-bottom: 25px;
                @media #{$small-mobile} {
                    font-size: 16px;
                    margin-bottom: 15px;
                }
            }
            .name{
                font-size: 18px;
                color: var(--color-primary);
                font-weight: 700;
            }
            span{
                display: block;
                font-weight: 400;
                font-size: 14px;
                color: #5D666F;
            }
        }
        .check-area-details{
            .single-check{
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                i{
                    margin-right: 15px;
                    color: var(--color-primary);
                    @media #{$small-mobile} {
                    margin-top: -26px;
                    }
                }
                span{
                    color: #5D666F;
                }
            }
        }
        .details-tag{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            @media #{$small-mobile} {
                justify-content: flex-start;
            }
            h6{
                margin-bottom: 0;
                font-size: 18px;
                margin-right: 15px;
            }
            button{
                padding: 8px 12px;
                background: #F6F6F6;
                max-width: max-content;
                margin-left: 10px;
                font-size: 14px;
                font-weight: 500;
                border-radius: 5px;
                color: #1C2539;
                transition: .3s;
                &:last-child{
                    @media #{$laptop-device} {
                        margin-top: 10px;
                        margin-left: -2px;
                    }
                }
                &:hover{
                    background: var(--color-primary);
                    color: #fff;
                    transform: translateY(-2px) scale(1.02);
                }
            }
        }
        .details-share{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @media #{$md-layout} {
                justify-content: flex-start;
                margin-top: 30px;
            }
            @media #{$sm-layout} {
                justify-content: flex-start;
                margin-top: 30px;
            }
            @media #{$large-mobile} {
                justify-content: flex-start;
                margin-top: 20px;
            }
            button{
                max-width: max-content;
                position: relative;
                z-index: 1;
                margin-left: 23px;
                color: #1C2539;
                transition: .3s;
                font-size: 14px;
                &::after{
                    position: absolute;
                    content: '';
                    background: #F6F6F6;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    z-index: -1;
                    transition: .3s;
                }
                &:hover{
                    color: #fff;
                    transform: scale(1.2);
                    &::after{
                        background: var(--color-primary);
                    }
                }
            }
            h6{
                font-size: 18px;
                margin-bottom: 0;
                margin-right: 15px;
            }
        }
        .author-area{
            margin-top: 44px;
            display: flex;
            align-items: center;
            padding: 40px 0;
            border-top: 1px solid #E6E9F0;
            border-bottom: 1px solid #E6E9F0;
            @media #{$sm-layout} {
                align-items: flex-start;
            }
            @media #{$large-mobile} {
                flex-wrap: wrap;
            }
            .thumbnail{
                margin-right: 30px;
                @media #{$sm-layout} {
                    margin-right: 0;
                }
            }
            .author-details{
                @media #{$sm-layout} {
                    margin-left: 15px;
                }
                h5{
                    margin-bottom: 10px;
                }
                p{
                    line-height: 26px;
                }
            }
        }

    }

    .replay-area-details{
        margin-top: 40px;
        form{
            input{
                height: 55px;
                border-radius: 15px;
                background: #F6F6F6;
                border: 1px solid transparent;
                &:focus{
                    border: 1px solid var(--color-primary);
                }
            }
            textarea{
                border-radius: 15px;
                background: #F6F6F6;
                height: 140px;
                margin-top: 20px;
                padding: 15px;
                border: 1px solid transparent;
                &:focus{
                    border: 1px solid var(--color-primary);
                }
            }
        }
    }


    .thumbnail{
        overflow: hidden;
        img{
            transition: .3s;
            width: 100%;
        }
        &:hover{
            img{
                transform: scale(1.2);
            }
        }
    }
    .blog-listing-content{
        padding: 50px;
        @media #{$sm-layout} {
            padding: 25px 10px;
        }
        .user-info{
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            flex-wrap: wrap;
            .single{
                margin-right: 30px;
                min-width: max-content;
                @media #{$sm-layout} {
                    margin-right: 5px;
                }
                @media #{$large-mobile} {
                    margin-right: 5px;
                }
                i{
                    margin-right: 10px;
                    color: var(--color-primary);
                    @media #{$large-mobile} {
                        margin-right: 2px;
                        font-size: 14px;
                    }
                }
                span{
                    @media #{$large-mobile} {
                        font-size: 13px;
                    }
                }
            }
        }
        .blog-title{
            transition: .3s;
            .title{
                transition: .3s;
                margin-bottom: 16px;
            }
            &:hover{
                .title{
                    color: var(--color-primary);
                }
            }
        }
        p{
            &.disc{
                font-size: 16px;
                line-height: 26px;
                // margin-bottom: 32px;
                @media #{$small-mobile} {
                    margin-bottom: 15px;
                }
            }
        }
        a{
            &.rts-btn{
                margin-top: 35px;
                display: block;
                max-width: max-content;
                @media #{$small-mobile} {
                    margin-top: 20px;
                }
            }
        }
    }
}

.rts-single-wized{
    background: #F6F6F6;
    border-radius: 0;
    padding: 40px;
    margin-bottom: 40px;
    &:last-child{
        margin-bottom: 0;
    }
    @media #{$small-mobile} {
        padding: 20px;
    }
    &.service{
        border-radius: 0;
        .single-categories{
            li{
                a{
                    border-radius: 0;
                }
            }
        }
    }
    &.download{
        background: #1C2539;
        .title{
            color: #fff;
        }
        .single-download-area{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 0;
            border-bottom: 1px solid #2E3951;
            &:first-child{ 
                padding-top: 0;
            }
            &:last-child{
                border-bottom: none;
                padding-bottom: 0;
            }
            .mid{
                margin-right: auto;
                margin-left: 15px;
                .title{
                    margin-bottom: 0;
                    font-size: 18px;
                    font-family: var(--font-primary);
                }
                span{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: #FFFFFF;
                }
            }
            a{
                &.rts-btn{
                    padding: 11px 15px;
                    border-radius: 0;
                }
            }
        }
    }
    &.contact{
        background: #1C2539;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 60px;
        @media #{$small-mobile} {
            padding: 25px 20px;
        }
        &:last-child{
            @media #{$small-mobile} {
                margin-bottom: 0;
            }
        }
        .wized-body{
            text-align: center;
            .title{
                color: #fff;
                margin-bottom: 30px;
                font-size: 22px;
                line-height: 32px;
            }
            a{
                &.rts-btn{
                    display: block;
                    max-width: max-content;
                    margin: auto;
                }
            }
        }
    }
    .wized-header{
        .title{
            margin-bottom: 10px;
        }
    }
    .wized-body{
        margin-top: 30px;
        .rts-search-wrapper{
            position: relative;
            input{
                background: #fff;
                height: 55px;
                border-radius: 5px;
                padding-right: 70px;
                padding-left: 25px;
                border: 1px solid transparent;
                &:focus{
                    border: 1px solid var(--color-primary);
                }
            }
            button{
                position: absolute;
                max-width: max-content;
                height: 55px;
                width: 55px;
                border-radius: 5px;
                background: var(--color-primary);
                display: inline-block;
                padding: 0 19px;
                right: 0;
                i{
                    color: #fff;
                    font-size: 16px;
                    line-height: 16px;
                }
            }
        }
    }
    .single-categories{
        margin-bottom: 15px;
        padding: 0;
        &:last-child{
            margin-bottom: 0;
        }
        li{
            list-style: none;
            a{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 15px 25px;
                background: #fff;
                color: #5D666F;
                font-weight: 500;     
                transition: .3s;  
                border-radius: 5px;         
                i{
                    color: var(--color-primary);
                    transition: .3s;
                }
                &:hover{
                    background: var(--color-primary);
                    transform: translateY(-5px) scale(1.03);
                    color: var(--color-white);  
                    i{
                        color: #fff;
                    }  
                }
            }
        }
    }
    // recent post
    .recent-post-single{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0;
        }
        .thumbnail{
            margin-right: 20px;
            overflow: hidden;
            max-width: max-content;
            width: 100%;
            border-radius: 5px;
            img{
                min-width: 85px;
                height: auto;
                transition: .3s;
            }
            &:hover{
                img{
                    transform: scale(1.2);
                }
            }
        }
        .user{
            display: flex;
            align-items: center;
            span{
                margin-left: 9px;
            }
        }
        .post-title{
            .title{
                margin-bottom: 0;
                font-size: 16px;
                font-weight: 600;
                color: #1C2539;
                line-height: 26px;
                margin-top: 5px;
                transition: .3s;
                @media #{$small-mobile} {
                    font-size: 14px;
                    line-height: 26px;
                    margin-top: 0;
                }
            }
            &:hover{
                .title{
                    color: var(--color-primary);
                }
            }
        }
    }
    // gallery post
    .gallery-inner{
        display: flex;
        flex-direction: column;
        .single-row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            a{
                overflow: hidden;
                border-radius: 6px;
                @media #{$small-mobile} {
                    display: block;
                    width: 100%;
                }
                img{
                    max-width: 97px;
                    height: auto;
                    transition: .3s;
                    @media #{$laptop-device} {
                        max-width: 80px;
                    }
                    @media #{$smlg-device} {
                        min-width: 269px;
                    }
                    @media #{$md-layout} {
                        min-width: 193px;
                    }
                    @media #{$sm-layout} {
                        min-width: 135px;
                    }
                    @media #{$large-mobile} {
                        min-width: 140px;
                    }
                    @media #{$small-mobile} {
                        min-width: 80px;
                    }
                }
                &:hover{
                    img{
                        transform: scale(1.2);
                    }
                }
            }
            &.row-1{
                margin-bottom: 20px;
            }
        }
    }
    .tags-wrapper{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -10px;
        a{
            padding: 5px 16px;
            background: #fff;
            border-radius: 5px;
            margin-right: 10px;
            margin-bottom: 10px;
            color: #1C2539;
            font-size: 14px;
            font-weight: 500;
            transition: .3s;
            &:hover{
                background: var(--color-primary);
                color: #fff;
                transform: translateY(-3px) scale(1.09);
            }
        }
    }
}

.rts-blog-list-area{
    .pagination{
        margin-top: 10px;
        max-width: max-content;
        button {
            padding: 15px;
            border: 1px solid #EBEBEB;
            border-radius: 15px;
            margin-right: 10px;
            color: #1C2539;
            font-weight: 700;
            height: 50px;
            width: 50px;
            line-height: 18px;
            i{
                font-size: 20px;
            }
            &.active{
                background: var(--color-primary);
                color: #ffff;
            }
            &:hover{
                background: var(--color-primary);
                color: #ffff;
            }
        }
    }
}

.rts-blog-grid-area{
    .pagination{
        margin-top: 10px;
        max-width: max-content;
        button {
            padding: 15px;
            border: 1px solid #EBEBEB;
            border-radius: 15px;
            margin-right: 10px;
            color: #1C2539;
            font-weight: 700;
            height: 50px;
            width: 50px;
            line-height: 18px;
            i{
                font-size: 20px;
            }
            &.active{
                background: var(--color-primary);
                color: #ffff;
            }
            &:hover{
                background: var(--color-primary);
                color: #ffff;
            }
        }
    }
}

.blog-grid-inner {
    border: 1px solid #E6E9F0;
    border-radius: 15px;
}

.blog-grid-inner .blog-header {
    position: relative;
}

.blog-grid-inner .thumbnail {
    overflow: hidden;
    border-radius: 15px 15px 0 0;
    width: 100%;
    display: block;
}

.blog-grid-inner .thumbnail img {
    width: 100%;
    transition: 0.3s;
}

.blog-grid-inner .blog-header .blog-info {
    position: absolute;
    display: flex;
    align-items: center;
    padding: 7px 30px;
    bottom: 0;
    background: #1C2539;
    width: 100%;
    left: 0;
}
.blog-grid-inner .thumbnail:hover img {
    transform: scale(1.2);
}
.blog-grid-inner .blog-header .blog-info .user {
    margin-right: 25px;
}
.blog-grid-inner .blog-header .blog-info .user i {
    color: #fff;
}
.blog-grid-inner .blog-header .date {
    position: absolute;
    padding: 10px 20px;
    border-radius: 15px;
    right: 20px;
    bottom: 20px;
    background: #fff;
    .title{
        margin-bottom: 0;
        color: var(--color-primary);
        line-height: 1;
    }
}
.blog-grid-inner .blog-header .blog-info .user span {
    color: #fff;
    font-size: 16px;
    font-weight: 400;
    margin-left: 8px;
}
.blog-grid-inner .blog-body {
    padding: 30px;
}
.blog-grid-inner .blog-body a:hover .title {
    color: var(--color-primary);
}



.blog-lg-inner{
    box-shadow: 0px 18px 33px rgba(15, 15, 45, 0.05);
    border-radius: 15px;
    &.single-blog-sm{
        margin-bottom: 24px;
        a.thumbnail{
            border-radius: 15px 15px 0px 0px;
            overflow: hidden;
            display: block;
            img{
                transition: .3s;
                width: 100%;
            }
            &:hover{
                img{
                    transform: scale(1.2);
                }
            }
        }
        .inner{
            padding: 35px;
            .date-area{
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                i{
                    color: var(--color-primary);
                    margin-right: 7px;
                    font-weight: 900;
                    font-size: 16px;
                }
                span{
                    color: #6F737B;
                    font-weight: 500;
                    font-size: 16px;
                    margin-bottom: -3px;
                }
            }
            a.title{
                .title{
                    transition: .3s;
                    margin-bottom: 13px;
                }
                &:hover{
                    .title{
                        color: var(--color-primary);
                    }
                }
            }
            p{
                &.disc{
                    margin-bottom: 14px;
                }
            }
            a{
                &.rts-read-more{
                    color: var(--color-primary);
                    font-weight: 700;
                    font-size: 16px;
                }
            }
        }
    }
    a.thumbnail{
        border-radius: 15px 15px 0px 0px;
        overflow: hidden;
        position: relative;
        display: block;
        span{
            padding: 8px 15px;
            background: #FFFFFF;
            border-radius: 15px;
            position: absolute;
            z-index: 0;
            top: 30px;
            left: 30px;
            color: var(--color-primary);
            text-transform: uppercase;
            font-weight: 800;
            font-size: 12px;
            line-height: 14px;
            font-family: var(--font-secondary);
        }
        img{
            transition: .3s;
            width: 100%;
        }
        &:hover{
            img{
                transform: scale(1.2);
            }
        }
    }
    .inner{
        padding: 35px;
        .date-area{
            display: flex;
            align-items: center;
            margin-bottom: 13px;
            i{
                color: var(--color-primary);
                margin-right: 7px;
                font-weight: 900;
                font-size: 16px;
            }
            span{
                color: #6F737B;
                font-weight: 500;
                font-size: 16px;
                margin-bottom: -3px;
            }
        }
        a.title{
            .title{
                transition: .3s;
                margin-bottom: 15px;
            }
            &:hover{
                .title{
                    color: var(--color-primary);
                }
            }
        }
        p{
            &.disc{
                margin-bottom: 16px;
            }
        }
        a{
            &.rts-read-more{
                color: var(--color-primary);
                font-weight: 700;
                font-size: 16px;
            }
        }
    }
}

// blog area home five

.blog-bg-h5{
    background: #F4F4F4;
}

.rts-blog-area-h5-single{
    display: flex;
    align-items: center;
    background: #fff;
    @media #{$sm-layout} {
        flex-direction: column;
    }
    a{
        display: block;
        overflow: hidden;
        @media #{$sm-layout} {
            width: 100%;
            max-width: 100%;
        }
        img{
            transition: 1s;
            width: 441px;
            height: 290px;
            object-fit: cover;
            @media #{$sm-layout} {
                width: 100%;
            }
        }
        &:hover{
            img{
                transform: scale(1.2);
            }
        }
    }
    .inner-area{
        padding: 40px 30px 40px 30px;
        @media #{$large-mobile} {
            margin-left: 20px;
            padding: 20px 20px 20px 0;
        }
        .title{
            font-weight: 700;
            font-size: 24px;
            line-height: 34px;
            color: #111A2E;
            font-family: var(--font-secondary);
            margin-bottom: 0;
            padding-bottom: 12px;
            border-bottom: 1px solid #E8E8E8;
            margin-bottom: 15px;
            transition: .3s;
            @media #{$sm-layout} {
                font-size: 20px;
                line-height: 30px; 
            }
            @media #{$large-mobile} {
                font-size: 18px;
                line-height: 26px;            
            }
            &:hover{
                color: var(--color-primary);
            }
        }
        .social-area{
            margin-left: 0;
            margin-bottom: 10px;
            .time-area{
                i{
                    color: var(--color-primary);
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 16px;
                }
                &.admin{
                    margin-left: 12px;
                }
            }
        }
        p{
            &.disc{
                margin-bottom: 0;
                @media #{$md-layout} {
                    font-size: 14px;
                }
                @media #{$sm-layout} {
                    font-size: 14px;
                }
            }
        }
    }
}


