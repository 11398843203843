#box {
    height: 500px;
    width: 500px;
    border: 2px solid red;
    position: relative;
  }
  #tooltip {
    opacity: 0;
    position: absolute;
    background-color: black;
    color: white;
    top: 10px;
    left: 0;
  }
  #box:hover #tooltip {
    opacity: 1;
  }









.mouse-hover{
    #listcontainer .li1 {
        display: block;
        width: 210px;
        height: 130px;
        border: 1px solid red;
        position: relative;
    }
    .li1 span {
        position: absolute;
        top: 140px;
        left: 0px;
        right: 0;
        width: 220px;
        padding: 2px 0;
        background-color: #000;
        background-color: rgba(0, 0, 0, 0.65);
        color: #fff;
        opacity: 0;
        transition: opacity .5s ease-in-out;
        text-align: center;
        font-family: Arial;
        font-size: 14px;
    }
    .li1:hover span {
        opacity: 1;
    }
    .li1:hover span:hover {
        opacity: 0;
    }
}