.counter-bg{
    background-image: url(../images/counter-up/01.jpg);
    background-size: cover;
}


.counter-up-wrapper-one{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .counter-single{
        @media #{$md-layout} {
            margin-right: 30px;
            margin-bottom: 30px;
            &:last-child{
                margin-bottom: 0;
            }
        }
        @media #{$sm-layout} {
            margin-right: 30px;
            margin-bottom: 30px;
            &:last-child{
                margin-bottom: 0;
            }
        }
        h2{
            font-weight: 700;
            font-size: 60px;
            line-height: 72px;
            color: #fff;
            max-width: max-content;
            margin-bottom: 0;
            span{
                position: relative;
                &::after{
                    position: absolute;
                    content: '+';
                    font-size: 60px;
                    right: -40px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            span{
                &.k-plus{
                    &::after{
                        content: 'k+';
                        right: -70px;
                    }
                }
            }
        }
        p{
            &.disc{
                font-weight: 700;
                font-size: 18px;
                line-height: 22px;
                color: #fff;
            }
        }
    }
}

// counter style home two


.counter-up-wrapper-two{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media #{$sm-layout} {
        justify-content: center;
    }
    .counter-single{
        text-align: center;
        border: 1px solid #3B38EB;
        width: 230px;
        height: 230px;
        border-radius: 50%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        @media #{$smlg-device} {
            width: 215px;
            height: 215px;
        }
        @media #{$sm-layout} {
            margin-bottom: 30px;
        }
        .title{
            font-style: italic;
            font-weight: 400;
            font-size: 60px;
            line-height: 72px;
            color: #3B38EB;
            margin-bottom: 2px;
        }
        p{
            &.disc{
                color: #111A2E;
                font-weight: 400;
                font-size: 18px;
                line-height: 22px;
            }
        }
    }
}