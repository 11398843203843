header{
    .active-dark{
        display: none;
    }
}

.header-one{
    position: absolute;
    width: 100%;
    z-index: 5;
    &.two{
        .rts-btn.btn-secondary.call-btn{
            &::before{
                border-radius: 13px;
                background: #ffffff;
            }
            &.seven{
                @media(max-width:500px){
                    display: none;
                }
                &::before{
                    background: #F3F3FB;
                }
            }
        }
        .rts-btn.btn-secondary.menu-btn{
            &::before{
                border-radius: 13px;
                background: #F3F3FB;
            }
        }
    }
    &.three{
        position: relative;
        background: #ffffff;
        .rts-btn.btn-secondary.call-btn{
            border: 1px solid #E5E5F0;
            &::before{
                border-radius: 13px;
            }
        }
        .rts-btn.btn-secondary.menu-btn{
            &::before{
                border-radius: 13px;
                background: #F3F3FB;
                border-radius: 13px;
            }
        }
    }
    .thumbnail{
        img{
            padding: 36px 0;
            @media #{$large-mobile} {
                padding: 14px 0;
            }
        }
    }
    .main-header{
        display: flex;
        justify-content: center;
        margin-right: -70px;
        .main-nav{
            .mainmenu{
                margin: 0;
                display: flex;
                li{
                    margin: 0 17px;
                    a{
                        padding: 20px 0 20px 0;
                        display: inline-block;
                        color: #111A2E;
                        font-weight: 500;
                        font-size: 16px;
                        font-family: var(--font-primary);
                    }
                    &.current{
                        a{
                            color: var(--color-primary);
                        }
                    }
                }
            }
        }
    }
    .header-right{
        display: flex;
        align-items: center;
        padding: 33px 0;
        justify-content: flex-end;
        @media #{$large-mobile} {
            padding: 12px 0;
        }
        .call-btn{
            svg{
                margin-left: 7px;
                path{
                    transition: var(--transition);
                }
            }
            &:hover{
                svg{
                    path{
                        fill: #fff;
                    }
                }
            }
        }
        .menu-btn{
            margin-left: 20px;
            padding: 18px 18px;
            &:hover{
                svg{
                    rect{
                        fill: #fff;
                    }
                }
            }
        }
    }
}

.sticky{
    .header-right{
        .rts-btn{
            &::before{
                background: #EFEFFE !important;
                border-radius: 11px !important;
            }
        }
    }
    &.header-one .thumbnail img {
        padding: 17px 0;
    }
    &.header-one .main-header .main-nav .mainmenu li a {
        padding: 29px 0;
    }
    &.header-one .header-right {
        padding: 14px 0;
    }
}

.header-top-start{
    &.header-three{
        background: var(--color-primary);
        padding: 6px 0;
        .left{
            display: flex;
            align-items: center;
            p{
                color: #fff;
                font-weight: 500;
                font-size: 14px;
                line-height: 18px;
                a{
                    transition: .3s;
                    &:hover{
                        color: #000;
                    }
                }
            }
        }
        .right{
            display: flex;
            align-items: center;
            justify-content: flex-end ;
            p{
                margin-bottom: 0;
                color: #fff;
                font-size: 14px;
                a{
                    transition: .3s;
                    &:hover{
                        color: #000;
                    }
                }
                &.mail{
                    margin-left: 30px;
                }
            }
        }
    }
}


.header-one{
    &.three{
        .header-right {
            @media #{$smlg-device} {
                display: flex;
                align-items: center;
                padding: 11px 0;
                justify-content: flex-end;
            }
        }
        .thumbnail img {
            @media #{$smlg-device} {
                padding: 18px 0;
            }
        }
    }
}
header{
    .header-right{
        .rts-btn{
            @media #{$small-mobile} {
                padding: 12px;
            }
        }
        #menu-btn{
            @media #{$small-mobile} {
                padding: 12px;
            }
        }
    }
}


// header-four

.header-top-start{
    &.header-three{
        &.header-four{
            background: #F4F4F4;
            .left{
                display: flex;
                .mail-area{
                    margin-right: 25px;
                }
                i{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 14px;
                    color: #3B38EB;
                    margin-right: 7px;
                }
                a{
                    color: #000000;
                    transition: .3s;
                    &:hover{
                        color: var(--color-primary);
                    }
                }
            }
            .right{
                .map-area{
                    display: flex;
                    align-items: center;
                    i{
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 14px;
                        color: #3B38EB;
                        margin-right: 7px;
                    }
                    p{
                        &.map{
                            color: #000000;
                            transition: .3s;
                        }
                    }
                }
            }
        }
    }
}


.header-one{
    &.three{
        &.four{
            .header-right{
                .rts-btn{
                    &.btn-secondary.call-btn{
                        color: #fff;
                        transition: .3s;
                        &:focus{
                            border: 1px solid #E5E5F0 !important;
                        }
                        svg{
                            path{
                                fill: #fff;
                                transition: .3s;
                            }
                        }
                        background: #fff;
                        &::before{
                            background: var(--color-primary);
                        }
                        &:hover{
                            color: var(--color-primary);
                            svg{
                                path{
                                    fill: var(--color-primary);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}


.header-one{
    &.three{
        &.four{
            &.sticky{
                .header-right{
                    .rts-btn{
                        &.btn-secondary.call-btn{
                            color: #fff;
                            transition: .3s;
                            &:focus{
                                border: 1px solid #E5E5F0 !important;
                            }
                            svg{
                                path{
                                    fill: #fff;
                                    transition: .3s;
                                }
                            }
                            background: #fff !important;
                            &::before{
                                background: var(--color-primary) !important;
                            }
                            &:hover{
                                color: var(--color-primary);
                                svg{
                                    path{
                                        fill: var(--color-primary);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

// header five

.header-one{
    &.five{
        a{
            &.thumbnail{
                .logo-1{
                    display: block;
                }
                .logo-2{
                    display: none;
                }
            }
        }
        &.sticky{
            a{
                &.thumbnail{
                    .logo-1{
                        display: none;
                    }
                    .logo-2{
                        display: block;
                    }
                }
            }
        }
        .main-header .main-nav .mainmenu li a{
            color: #fff;
        }
        .main-header .main-nav .mainmenu li{
            .submenu{
                li{
                    a{
                        color: #111A2E;
                    }
                }
            }
        }
        .header-right{
            a{
                &.call-btn{
                    color: #fff;
                    background: #fff;
                    svg{
                        path{
                            fill: #fff;
                        }
                    }
                    &::before{
                        background: var(--color-primary);
                    }
                    &:hover{
                        color: var(--color-primary);
                        svg{
                            path{
                                fill: var(--color-primary);
                            }
                        }
                    }
                }
            }
        }
        &.sticky{
            .header-right{
                a{
                    &.rts-btn{
                        &.call-btn{
                            color: #fff;
                            transition: 0.3s;
                            background: #EFEFFE !important;
                            &::before{
                                background: var(--color-primary) !important;
                            }
                            &:hover{
                                color: var(--color-primary);
                                svg{
                                    path{
                                        fill: var(--color-primary);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.container-2{
    max-width: 1620px;
    margin: auto;
}

.header-one{
    &.five.sticky{
        .mainmenu{
            li{
                a{
                    color: #000000 !important;
                }
            }
        }
    }
}

ul{
    &.submenu{
        box-shadow: 0px 7px 18px #1810100d;
        &.home-mega{
            display: flex !important;
            align-items: center;
            justify-content: flex-start;
            width: 430px; 
            .multiple{
                width: 210px;
                position: relative;
                li{
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
                &::after{
                    position: absolute;
                    right: 10px;
                    height: 100%;
                    background: #EBEBEB;
                    width: 1px;
                    content: '';
                    top: 0;
                }
            }  
            .single{
                width: 220px;
            }     
        }
        &.home-mega2{
            width: 660px; 
            left: -350px !important;
            &::after{
                display: none;
            }
            .multiple{
                width: 210px;
                position: relative;
                
                li{
                    margin-top: 5px;
                    margin-bottom: 5px;
                }
                &.last{
                    &::after{
                        display: none;
                    }
                }
            }  
            .single{
                width: 220px;
            }     
        }
    }
}


header{
    a{
        .stickys{
            display: none;
        }
    }
    &.sticky{
        a{
            .normal{
                display: none;
            }
            .stickys{
                display: block;
            }
        }
    }
}

.side-bar{
    .logo-1{
        display: block;
    }
    .logo-2{
        display: none;
    }
}
.header-one .main-header .main-nav .mainmenu li{
    &.current{
        a{
            color: var(--color-primary) !important;
        }
    }
}

.header-one.five.sticky .mainmenu li a{
    &:hover{
        color: var(--color-primary) !important;
    }
}
.header-one.five.sticky .mainmenu li.has-droupdown a{
    &:hover{
        color: var(--color-primary) !important;
    }
}
.container-full-screen{
    width: 100%;
    padding: 0 50px;
    @media(max-width:500px){
        padding: 0 25px;
    }
}

.index-six{
    .call-btn{
        @media(max-width:400px){
            display: none;
        }
    }
}