.contact-map-area-fluid{
    position: relative;
    .contact-map{
        width: 100%;
        height: 580px;
    }
    .location{
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 5;
        transform: translate(-50%, -50%);
        max-width: 100px;
        height: auto;
    }   
}


.rts-contact-fluid{
    box-shadow: 0px 24px 39px rgba(0, 0, 0, 0.05);
    .form-wrapper{
        width: 80%;
        margin: auto;
        @media #{$large-mobile} {
            width: 90%;
        }
        .error{
            color: var(--color-primary);
            margin-bottom: 15px;
        }
        .success{
            color: green;
            margin-bottom: 15px;
        }
    }
}
.rts-contact-fluid{
    .form-wrapper{
        form{
            .name-email{
                display: flex;
                align-items: center;
                justify-content: space-between;
                @media #{$sm-layout} {
                    flex-direction: column;
                }
                input{
                    width: 48.8%;
                    padding-left: 25px;
                    @media #{$sm-layout} {
                        width: 100%;
                    }
                }
            }
        }
        input{
            background: #F6F6F6;
            height: 55px;
            border-radius: 15px;
            margin-bottom: 25px;
            border: 1px solid transparent;
            padding-left: 25px;
            &:focus{
                border: 1px solid var(--color-primary);
            }
        }
        textarea{
            height: 150px;
            background:#F6F6F6;
            border-radius: 15px;
            padding: 15px 15px;
            border: 1px solid transparent;
            padding-left: 25px;
            &:focus{
                border: 1px solid var(--color-primary);
            }
        }
    }
    .rts-btn{
        display: block;
        max-width: max-content;
        margin: auto;
        margin-top: 30px;
    }
}



.rts-send-message-area-h4{
    overflow: hidden;
    position: relative;
    z-index: 1;
    .thumbnail{
        position: absolute;
        left: 50%;
        top: -20%;
        transform: translateX(-50%);
        z-index: -1;
    }
    .content{
        .title{
            text-align: center;
            padding-top: 140px;
            font-size: 90px;
            font-weight: 100;
            @media #{$md-layout} {
                font-size: 54px;
                padding-top: 60px;
            }
            @media #{$sm-layout} {
                font-size: 42px;
                padding-top: 38px;
            }
            @media #{$large-mobile} {
                font-size: 36px;
                padding-top: 38px;
            }
            @media #{$small-mobile} {
                font-size: 26px;
                padding-top: 38px;
            }
            span{
                &.bold{
                    color: #111A2E;
                    font-weight: 700;
                    line-height: 100px;
                }
                &.italic{
                    font-style: italic;
                    font-weight: 400;
                    color: #3B38EB;
                }
            }
        }
        .button-area{
            display: flex;
            justify-content: center;
            button{
                position: relative;
                transition: 0.3s;
                width: max-content;
                span{
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    color: #fff;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 19px;
                    min-width: max-content;
                    font-family: var(--font-secondary);
                    transition: 0.3s;
                }
                &:hover{
                    transform: scale(0.9);
                    span{
                        transform: translate(-50%, -50%) scale(0.9);
                    }
                }
            }
        }
    }
}

