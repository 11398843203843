


body {
    scroll-behavior: auto;
    background: #ffffff;
    overflow-x: hidden;
}
/*------------------------
    Header Sticky 
--------------------------*/

// custom column

.col-lg-20{
    width: 20%;
    float: left;
}

.col-xs-20,
.col-sm-20,
.col-md-20,
.col-lg-20 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 15px;
    padding-top: 15px;
}

.col-lg-20{
    @media (min-width: 1200px) {
        width: 20%;
        float: left;
    }
    @media #{$smlg-device} {
        width: 33%;
        float: left;
    }
    @media #{$md-layout} {
        width: 50%;
        float: left;
    }
    @media #{$sm-layout} {
        width: 50%;
        float: left;
    }
    @media #{$large-mobile} {
        width: 50%;
        float: left;
    }
    @media #{$small-mobile} {
        width: 100%;
        float: left;
    }
}

::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #5D666F !important;
    opacity: 1 !important; /* Firefox */
    font-size: 16px;
}
  
  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #5D666F  !important;
    font-size: 16px;
  }
  
  ::-ms-input-placeholder { /* Microsoft Edge */
    color: #5D666F !important;
    font-size: 16px;
  }


.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}


// start hear

.bg-1{
    background: #e00a0a;
}
.bg-secondary{
    background: #1D2027!important;
}

.bg-white{
    background: #FFFFFF;
}

.bg-footer-one{
    background-repeat: no-repeat;
    background-size: cover;
    background: #0E1422;
    position: relative;
    overflow: hidden;
    z-index: 1;
    &::after{
        content: "";
        position: absolute;
        background-image: url(../images/footer/h1-shape/01.svg);
        height: 450px;
        width: 450px;
        right: -3%;
        background-repeat: no-repeat;
        bottom: -5%;
        @media #{$laptop-device} {   
            right: -15%;
        }
        @media #{$smlg-device} {   
            right: -15%;
        }
        @media #{$md-layout} {   
            left: 53%;
        }
    }
    &::before{
        content: "";
        position: absolute;
        background-image: url(../images/footer/h1-shape/02.png);
        height: 800px;
        width: 823px;
        left: -16%;
        background-position: center;
        bottom: -13%;
        background-size: cover;
        z-index: -1;
        background-repeat: no-repeat;
        @media #{$laptop-device} {
            left: -25%;
        }
        @media #{$smlg-device} {
            left: -25%;
        }
        @media #{$md-layout} {
            left: -48%;
        }
    }
    .bg-shape-f1{
        position: relative;
        z-index: 1;
        &::after{
            position: absolute;
            background-image: url(../images/footer/h1-shape/03.png);
            content: '';
            height: 500px;
            width: 523px;
            left: -25%;
            top: -20%;
            z-index: -1;
            background-repeat: no-repeat;
            @media #{$laptop-device} {
                left: -30%;
                top: -32%;
            }
            @media #{$smlg-device} {
                left: -30%;
                top: -32%;
            }
        }
    }
}


// title pre-title



.over_link{
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 9;
    left: 0;
    top: 0;
}


.title-area{
    span{
        font-weight: 600;
        font-size: 16px;
        line-height: 19px;
        color: #3B38EB;
    }
    .title{
        margin-top: 10px;
        margin-bottom: 30px;
        @media #{$sm-layout} {
            margin-bottom: 15px;
        }
    }
}

.bg-light{
    background: rgba(236, 242, 246, 1);
}


.header--sticky {
    transition: 0.3s;
    top: 0;
    z-index: 999;
    position: relative;
    &.sticky{
        position: fixed !important;
        top: 0;
        display: block;
        backdrop-filter: blur(9px);
        width: 100%;
        box-shadow: 0px 7px 18px #1810100d;
        background: #fff;
        z-index: 999;
    }
}


.bg_dark{
    background: #1C1C1C;
}


.g-6, .gy-6 {
    --bs-gutter-y: 4rem !important;
}
.g-6, .gx-6 {
    --bs-gutter-x: 4rem !important;
}
.g-24, .gy-24 {
    --bs-gutter-y: 2.4rem !important;
}
.g-24, .gx-24 {
    --bs-gutter-x: 2.4rem !important;
}
