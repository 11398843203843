
// accordionm area start style

.accordion-wrapper-area{
    .accordion{
        .accordion-item{
            border: none;
            .accordion-header{
                button{
                    height: 60px;
                    font-weight: 600;
                    font-size: 17px;
                    line-height: 20px;
                    padding: 0 25px;
                    @media #{$large-mobile} {
                        font-size: 14px;
                    }
                    &::after{
                        background-image: none;
                        content: '\f078';
                        font-family:"Font Awesome 5 Pro" !important;
                        transform: none;
                        margin-top: -10px;
                    }
                    &[aria-expanded="true"]{
                        background: #FFFFFF;
                        box-shadow: 0px 13px 46px rgba(0, 0, 0, 0.07);
                        border-radius: 15px;
                        height: 60px;
                        font-weight: 600;
                        font-size: 17px;
                        line-height: 20px;
                        color: #111A2E;
                        @media #{$large-mobile} {
                            font-size: 14px;
                        }
                        &::after{
                            content: '\f077';
                            color: var(--color-primary);
                        }
                    }
                    &:focus{
                        box-shadow: 0px 13px 46px rgba(0, 0, 0, 0.07);
                        border-radius: 15px;
                    }
                }
            }
            .accordion-body{
                font-weight: 400;
                font-size: 16px;
                line-height: 26px;
                color: #6F737B;
                padding:25px;
            }
        }
    }
    &.wrapper-area-inner{
        .accordion{
            .accordion-item{
                box-shadow: 0px 12px 52px rgba(46, 44, 165, 0.1);
                border-radius: 15px;
                margin-bottom: 20px;
                .accordion-header{
                    button{
                        border-radius: 15px;
                        span{
                            color: var(--color-primary);
                            margin-right: 5px;
                        }
                        &[aria-expanded="true"]{
                            box-shadow: none;
                        }
                        &:focus{
                            box-shadow: none;
                            border-radius: 15px;
                        }
                    }
                }
                .accordion-body{
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 26px;
                    color: #6F737B;
                    padding:25px;
                }
            }
        }
    }
}


